import React from "react";
import { navigate } from "gatsby";
import { UserContext, handleLogout } from "@parallelpublicworks/entitysync";
import Alert from "@material-ui/lab/Alert";

function Logout() {
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    handleLogout([
      userContext,
      function () {
        navigate("/login");
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Alert style={{ justifyContent: "center" }}>Logging out...</Alert>;
}

export default Logout;
